//Ma_postulation
import React, { useState } from 'react';
import { useRef,useEffect } from 'react';
import S_nav2 from './s_nav2';
import { useParams } from 'react-router-dom';
function Ma_postulation()
{
    var ob;
  const [postulation,setPostulation]=useState([{ _id:"",id_candidat:"",id_annonce:"",date_postulation:"",poste_postuler:""}]);
  let vst=JSON.parse(localStorage.getItem("con"));
    async function afficher()
    {
    await fetch(`https://api-careercruiser.onrender.com/postuler/${vst.ID_ADM}`)
    .then(response => response.json())
    .then(data => ob = data);
    if(postulation==undefined||postulation[0].id_annonce=="")
    {
        setPostulation(ob);  
    }
    }
    useEffect(()=>{
        afficher();
        console.log(postulation);
        },[postulation]);
        function annuler_p(id)
        {
            fetch(`https://api-careercruiser.onrender.com/postuler/postulation/${id}`, { method: 'DELETE' })
        .then(() => this.setState({ status: 'Delete successful' }));
        }
    return(<>
    <div class="parent_">
    <div class="div1_"><S_nav2/> </div>
        <div class="div2_">
        <div  className='upd_c'>
      <h1>Postulation</h1>
    <table class="fl-table">
        <thead>
            <tr>
                <th>poste</th>
                <th>date postulation</th>
                <th></th>
            </tr>
            {postulation.map(postu=>( <tr>
                <td>{postu.poste_postuler}</td>
                <td>{postu.date_postulation}</td>
                <td><button onClick={()=>annuler_p(postu._id)}>annuler</button></td>
            </tr>))}
        </thead>
           
            
    </table>
    </div>
        </div>
    </div>
    </>
        );
}
export default Ma_postulation;