import React, { useState } from 'react';
import { useRef,useEffect } from 'react';
import S_nav from './s_nav';
import { useParams } from 'react-router-dom';
function Update_candidat()
{
  let { id } = useParams();
  const [candidat,setCandidat]=useState({nom:"",prenom:"",adresse:"",email:"",tel:"",mot_de_passe:"",genre:"",date_de_naissance:"",niveau_etude:"",photo_url:"",autorisation:""});
  var ob={};
  async function afficher()
  {
    await fetch(`https://api-careercruiser.onrender.com/candidat/${id}`)
    .then(response => response.json())
    .then(data => ob = data);
    if(candidat==undefined||candidat.nom=="")
    {
      setCandidat(ob);
    }
  }
    useEffect(() => {
      afficher();   
      console.log(candidat);
      console.log(ob);
    }, [candidat]);
    const _nom =useRef();
    const _prenom=useRef();
    const _adresse=useRef();
    const _email=useRef();
    const _mot_de_passe=useRef();
    const _mot_de_passe_=useRef();
    const _tel=useRef();
    const _date_de_naissance=useRef();
    const _niveau_etude=useRef();
    const _genre=useRef();
    const _photo_url=useRef();
   
    async function update_candidat()
    {
      var noveau_candidat_info={};
      
      if(_photo_url.current.value!=="")
      {
        const photo_url=_photo_url.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{photo_url}};
        console.log(noveau_candidat_info);
      }
      if(_nom.current.value!=="")
      {
        const nom=_nom.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{nom}};
        console.log(noveau_candidat_info);
      }
      if(_prenom.current.value!=="")
      {
        const prenom=_prenom.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{prenom}};
        console.log(noveau_candidat_info);
      }
      if(_genre.current.value!=="")
      {
        const genre=_genre.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{genre}};
        console.log(noveau_candidat_info);
      }
      if(_tel.current.value!=="")
      {
        const tel=_tel.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{tel}};
        console.log(noveau_candidat_info);
      }
      if(_adresse.current.value!=="")
      {
        const adresse=_adresse.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{adresse}};
        console.log(noveau_candidat_info);
      }
      if(_email.current.value!=="")
      {
        const email=_email.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{email}};
        console.log(noveau_candidat_info);
      }
      if(_date_de_naissance.current.value!=="")
      {
        const date_de_naissance=_date_de_naissance.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{date_de_naissance}};
        console.log(noveau_candidat_info);
      }
      if(_niveau_etude.current.value!=="")
      {
        const niveau_etude=_niveau_etude.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{niveau_etude}};
        console.log(noveau_candidat_info);
      }
      if(_mot_de_passe.current.value!=="")
      {
        if(_mot_de_passe.current.value===_mot_de_passe_.current.value)
        {
          const mot_de_passe=_mot_de_passe.current.value;
          noveau_candidat_info={...noveau_candidat_info,...{mot_de_passe}};
          console.log(noveau_candidat_info);
        }else{
          alert("Vérifiez le mot de passe");
        }
      }
     
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(noveau_candidat_info)
         };
      const response = await fetch(`https://api-careercruiser.onrender.com/candidat/${id}`, requestOptions);
      const data = await response.json();
    }
    return(<>
    <div class="parent_">
    <div class="div1_"><S_nav/> </div>
        <div class="div2_">
    <div className='form_wrapper_'>
    <div className="form_wrapper">
  <div className="form_container">
    <div className="title_container">
      <h2>Modifier les informations de la candidat</h2>
    </div>
    <div className="row clearfix">
      <div className="">
        <form>
        <div className="row clearfix">
            <div className="col_half">
              <div className="input_field">
              <label>prenom</label>
                <input type="text" ref={_prenom}  placeholder={candidat.prenom} />
              </div>
            </div>
            <div className="col_half">
              <div className="input_field">
              <label>nom</label>
                <input type="text" ref={_nom} placeholder={candidat.nom} required />
              </div>
            </div>
          </div>
          <div className="input_field">
          <label>photo profile</label>
            <input type="text" ref={_photo_url} placeholder={candidat.photo_url} required />
          </div>
          <div className="input_field">
          <label>genre</label>
            <input type="text" ref={_genre} placeholder={candidat.genre} required />
          </div>
          <div className="input_field">
          <label>tel</label>
            <input type="text" ref={_tel} placeholder={candidat.tel} required />
          </div>
          <div className="input_field"> 
          <label>adresse</label>
            <input type="text" ref={_adresse} placeholder={candidat.adresse} required />
          </div>
          <div className="input_field"> 
          <label>email</label>
            <input type="email" ref={_email} placeholder={candidat.email} required />
          </div>
          <div className="input_field"> 
          <label>date de naissance</label>
            <input type="password" ref={_date_de_naissance} placeholder={candidat.date_de_naissance} required />
          </div>
          <div className="input_field"> 
          <label>niveau d'étude</label>
            <input type="password" ref={_niveau_etude} placeholder={candidat.niveau_etude} required />
          </div>
          <div className="input_field"> 
          <label>mot de passe</label>
            <input type="password" ref={_mot_de_passe} placeholder={candidat.mot_de_passe} required />
          </div>
          <div className="input_field"> 
          <label>confirmer mot de passe</label>
            <input type="password" ref={_mot_de_passe_} placeholder={candidat.mot_de_passe} required />
          </div>

          <a className='reg_btn' onClick={update_candidat} >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            enregistrer la modification
        </a>  
        </form>
      </div>
    </div>
  </div>
</div>
    </div>
        </div>
    </div>
    </>
        );
}
export default Update_candidat;