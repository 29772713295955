import React from 'react';
import { useRef,useState,useEffect } from 'react';
import './connect.css';
function Connect ()
{
  var ob;
  var con={};
  const mot_passe=useRef();
  const email=useRef();
  const [admin_,setAdmin_]=useState([{nom:"",prenom:"",adresse:"",
  email:"",mot_de_passe:"",mot_de_passe_:"",tel:"",_id:""}]);
      async function afficher()
  {
    await fetch('https://api-careercruiser.onrender.com/admin')
    .then(response => response.json())
    .then(data => ob = data);
    if(admin_==undefined||admin_[0].nom=="")
    {
        setAdmin_(ob);  
    }
    
  }
      useEffect(()=>{
      afficher();
      },[admin_])

      
    function connect()
    {
        let conn=false;
        for(let user of admin_)
        {
            if(((user.email).trim()===(email.current.value).trim())&&((user.mot_de_passe).trim()===(mot_passe.current.value).trim()))
            {
                conn=true;
                con={email:(user.email).trim(),
                mot_passe:(user.mot_de_passe).trim(),
                ID_ADM:(user._id).trim(),
                visiteur:"admin"}  
                break;
            }
        }
        if(conn===true)
        {
            localStorage.setItem("con",JSON.stringify(con));
            window.location.href="/";
        }
    }
      
    return(<>
    <div className="login-box">
  <h2>Login</h2>
  <form>
    <div className="user-box">
      <input type="email" ref={email}  required=""/>
      <label>email</label>
    </div>
    <div className="user-box">
      <input type="password" ref={mot_passe} required=""/>
      <label>Password</label>
    </div>
    <a href="#" onClick={connect}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      Connect
    </a>
  </form>
</div>
        </>
            );
    
}
export default Connect;