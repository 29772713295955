import React from 'react';
import { useRef } from 'react';
import './administration.css';
import S_nav from './s_nav';
function Inscription ()
{
    const nom =useRef();
    const prenom=useRef();
    const adresse=useRef();
    const email=useRef();
    const mot_de_passe=useRef();
    const mot_de_passe_=useRef();
    const tel=useRef();

    function ajouter_admin()
    {
      if((nom.current.value==="")||(prenom.current.value==="")||(adresse.current.value==="")||(email.current.value==="")||(mot_de_passe.current.value==="")||(tel.current.value==="")||(mot_de_passe_.current.value===""))
      {
        alert("Remplissez tous les champs");
      }else
      {
        if(mot_de_passe.current.value===mot_de_passe_.current.value)
        {
          const post={
            nom:nom.current.value,
            prenom:prenom.current.value,
            adresse:adresse.current.value,
            email:email.current.value,
            tel:tel.current.value,
            mot_de_passe:mot_de_passe.current.value};
            fetch("https://api-careercruiser.onrender.com/admin",{
              method:"POST",
              headers:{"content-type":"application/json"},
              body:JSON.stringify(post)
            }).then(()=>{
              alert("ajouté avec succès");
            })
        }else
        {
          alert("Vérifiez le mot de passe");
        }
      }
    }
    return(<>
    <div class="parent_">
    <div class="div1_"><S_nav/> </div>
        <div class="div2_">
    <div className='form_wrapper_'>
    <div className="form_wrapper">
  <div className="form_container">
    <div className="title_container">
      <h2>ajouter nouvel admin</h2>
    </div>
    <div className="row clearfix">
      <div className="">
        <form>
        <div className="row clearfix">
            <div className="col_half">
              <div className="input_field"> <span><i aria-hidden="true" className="fa fa-user"></i></span>
                <input type="text" ref={prenom}  placeholder="prenom" />
              </div>
            </div>
            <div className="col_half">
              <div className="input_field"> <span><i aria-hidden="true" className="fa fa-user"></i></span>
                <input type="text" ref={nom} placeholder="nom" required />
              </div>
            </div>
          </div>
          <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope"></i></span>
            <input type="text" ref={tel} placeholder="tel" required />
          </div>
          <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope"></i></span>
            <input type="text" ref={adresse} placeholder="adresse" required />
          </div>
          <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope"></i></span>
            <input type="email" ref={email} placeholder="Email" required />
          </div>
          <div className="input_field"> <span><i aria-hidden="true" className="fa fa-lock"></i></span>
            <input type="password" ref={mot_de_passe} placeholder="Password" required />
          </div>
          <div className="input_field"> <span><i aria-hidden="true" className="fa fa-lock"></i></span>
            <input type="password" ref={mot_de_passe_} placeholder="Re-type Password" required />
          </div>
          
          <a className='reg_btn' onClick={ajouter_admin} >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            ajouter
        </a>  
        </form>
      </div>
    </div>
  </div>
</div>
    </div>
        </div>
    </div>
    </>
        );
    
}
export default Inscription;