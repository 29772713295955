import React from 'react';
import { useRef,useState,useEffect} from 'react';
import Footer from './footer';
import "./connect_candidat.css";
import emailjs from 'emailjs-com';
function Connect_candidat ()
{
  const _nom =useRef();
    const _prenom=useRef();
    const _adresse=useRef();
    const _email=useRef();
    const _mot_de_passe=useRef();
    const _mot_de_passe_=useRef();
    const _tel=useRef();
    const _date_de_naissance=useRef();
    const _niveau_etude=useRef();
    const _domain=useRef();
    const _description=useRef();
    const _nom_entreprise=useRef();
    const con_mot_de_passe=useRef()
    const con_email=useRef();
    const _photo_url=useRef();
    let keyword="";
    useEffect((e)=>{
        document.addEventListener('keydown',detectKeyDown,true)
    },[])
    const detectKeyDown =(e)=>{
        keyword+=e.key;
        if(e.key===" ")
        {keyword="";}
        if((keyword==="admin")&&(con_email.current.value==="")&&(con_mot_de_passe.current.value===""))
        {
            window.location.href="Connect";
        }
    }    

    var _genre="";
    function choix()
    {
      if(document.querySelector('input[name="tpins"]:checked').value==="candidat")
      {
        const elements = document.getElementsByClassName("recruteur");
         for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = "none";
            document.getElementById('candidat').style.display = "flex";
            document.getElementById('rpls').style.display = "flex";
          }
      }else
      {
        const elements = document.getElementsByClassName("recruteur");
         for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = "flex";
            document.getElementById('candidat').style.display = "none";
            document.getElementById('rpls').style.display = "none";
          }
      }
    }
     
  function inscription_candidat ()
  {
    if(document.querySelector('input[name="tpins"]:checked').value==="recruteur")
    {
      if((_nom.current.value==="")||(_prenom.current.value==="")||(_adresse.current.value==="")||(_email.current.value==="")||(_tel.current.value==="")||(_mot_de_passe_.current.value==="")||(_mot_de_passe.current.value==="")||(_description.current.value==="")||(_domain.current.value==="")||(_nom_entreprise.current.value===""))
      {
        alert("Remplissez tous les champs");
      }else{
        if(_mot_de_passe.current.value===_mot_de_passe_.current.value)
        {
          const recruteur={
            nom:_nom.current.value,
            prenom:_prenom.current.value,
            adresse:_adresse.current.value,
            email:_email.current.value,
            tel:_tel.current.value,
            mot_de_passe:_mot_de_passe.current.value,
            domain:_domain.current.value,
            description:_description.current.value,
            nom_entreprise:_nom_entreprise.current.value,
            photo_url:_photo_url.current.value
          }
          fetch("https://api-careercruiser.onrender.com/recruteur",{
                method:"POST",
                headers:{"content-type":"application/json"},
                body:JSON.stringify(recruteur)
              }).then(()=>{
                sendEmail(_email.current.value,_nom.current.value);
              })
          console.log(recruteur);
        }else{
          alert("Vérifiez le mot de passe");
        }
      }
        
    }else{
      if((_nom.current.value==="")||(_prenom.current.value==="")||(_adresse.current.value==="")||(_email.current.value==="")||(_tel.current.value==="")||(_date_de_naissance.current.value==="")||(_niveau_etude.current.value==="")||(_mot_de_passe_.current.value==="")||(_mot_de_passe.current.value===""))
    {
      alert("Remplissez tous les champs");
    }else{
      if(_mot_de_passe.current.value===_mot_de_passe_.current.value)
      {
        const candidat_={nom:_nom.current.value,
          prenom:_prenom.current.value,
          adresse:_adresse.current.value,
          email:_email.current.value,
          tel:_tel.current.value,
          mot_de_passe:_mot_de_passe.current.value,
          genre:_genre,
          date_de_naissance:_date_de_naissance.current.value,
          niveau_etude:_niveau_etude.current.value,
          photo_url:_photo_url.current.value
        }
        fetch("https://api-careercruiser.onrender.com/candidat",{
              method:"POST",
              headers:{"content-type":"application/json"},
              body:JSON.stringify(candidat_)
            }).then(()=>{
              sendEmail(_email.current.value,_nom.current.value);
              alert("inscription avec succès");
              window.location.href="/";
            })
        
      }else{
        alert("Vérifiez le mot de passe");
      }
    }
    }
  }

  function gr()
  {
    _genre=document.querySelector('input[name="_genre"]:checked').value;
  }
  
  /********************************************************** */
  const [user,setUser]=useState([{nom:"",prenom:"",adresse:"",
  email:"",mot_de_passe:"",mot_de_passe_:"",tel:"",_id:""}]);
  var ob;
  var obb;

  var con={};
  async function telecharger_donne()
  {
    await fetch('https://api-careercruiser.onrender.com/recruteur')
    .then(response => response.json())
    .then(data => ob = data);

    await fetch('https://api-careercruiser.onrender.com/candidat')
    .then(response => response.json())
    .then(data => obb = data);

    ob=[...ob,...obb];
    if(user==undefined||user[0].nom=="")
    {
        setUser(ob);  
    } 
  }
  useEffect(()=>{
    telecharger_donne();
    },[user])

    function connect()
    {
        let conn=false;
        for(let userr of user)
        {
          var vst="";
            if(((userr.email).trim()===(con_email.current.value).trim())&&((userr.mot_de_passe).trim()===(con_mot_de_passe.current.value).trim()))
            {
                conn=true;
                if(userr.nom_entreprise==undefined)
                {
                  vst="candidat"
                }
                else{
                  vst="recruteur"
                }
                con={email:(userr.email).trim(),
                mot_passe:(userr.mot_de_passe).trim(),
                ID_ADM:(userr._id).trim(),
                visiteur:vst}  
                break;
            }
        }
        if(conn===true)
        {
          localStorage.setItem("con",JSON.stringify(con));
          let vst=JSON.parse(localStorage.getItem("con"));
          if(vst.visiteur === "candidat")
          {
            window.location.href="/Info_candidat";
          }else{
            window.location.href="/Info_recruteur";
          }
           
        }
    }
    const sendEmail = (email,nom) => {
      emailjs.send(
        'service_n3kjanv', // Replace with your service ID
        'template_va05rrv', // Replace with your template ID
        {
          to_email: email,
          Nom_personne:nom // Replace with the recipient's email address
          // Add other email parameters as needed
        },
        '4hiFMNEAUx2sCuTaX' // Replace with your user ID
      )
        .then((response) => {
          console.log('Email sent successfully!', response);
        })
        .catch((error) => {
          console.error('Error sending email:', error);
        });
    };
        return(<>
        <div className='P_main'>

         <div class="main">
    <input type="checkbox" id="chk" aria-hidden="true"/>

    <div class="signup">
      <div>
        <label for="chk" aria-hidden="true">inscription</label>
        <span className='rdyo'>
        candidat<input type="radio" onClick={choix}  name="tpins" value="candidat"  />
        recruteur<input type="radio" name="tpins" onClick={choix} value="recruteur" checked />
        </span>
        <span className='rdyo'>
        photo de profil
          <input type="text" ref={_photo_url}  placeholder="photo_url" required=""/>
        </span>
        <span className='rdyo'>
        nom
          <input type="text" ref={_nom}  placeholder="nom" required=""/>
        </span>
        <span className='rdyo'>
        penom
          <input type="text" ref={_prenom}  placeholder="prenom" required=""/>
        </span>
        <span className='rdyo'>
        adresse
          <input type="text" ref={_adresse}  placeholder="adresse" required=""/>
        </span>
        <span className='rdyo'>
        email
         <input type="email" ref={_email}  placeholder="exemple@email.com " required=""/>
        </span>
        <span className='rdyo'>
        mot de passe
          <input type="password" ref={_mot_de_passe}  placeholder="******" required=""/>
        </span>
        <span className='rdyo'>
        confirmer mot de passe
        <input type="password" ref={_mot_de_passe_}  placeholder="********" required=""/>
        </span>
        <span className='rdyo'>
        tel
         <input type="text" ref={_tel}  placeholder="06.01.00.00.00" required=""/>
        </span>
        <span className='rdyo' id='rpls'>
        date_de_naissance
          <input type="text" ref={_date_de_naissance}  placeholder="jj-mm-aaaa" required=""/>
        </span>
        <span className='rdyo' id='candidat'>
        niveau d'étude
          <input type="text" ref={_niveau_etude}  placeholder="Bac+2,bac+5,deug,master" required=""/>
        </span>
        <span className='rdyo' id='rpls' name='rpls' >
        mâle<input type="radio" onClick={gr}  name="_genre" value="mâle" checked />
        femelle<input type="radio" onClick={gr} name="_genre" value="femelle"  /> 
        </span>
        <span className='rdyo recruteur'>
        nome de société
          <input type="text" ref={_nom_entreprise}  placeholder="exemple : CareerCruiser" required=""/>
        </span>
        <span className='rdyo recruteur'>
        description
        <textarea rows="7" cols="50" ref={_description}></textarea>
        </span>
        <span className='rdyo recruteur'>
        domain
          <input type="text" ref={_domain}  placeholder="Informatique,Marketing,Administration" required=""/>
        </span>
        <button className='btn_connect_candidat'  onClick={inscription_candidat}>inscription</button>
      </div>
    </div>

    <div class="login">
      <div>
        <label for="chk" aria-hidden="true">Connexion</label>
        <input type="email" ref={con_email} placeholder="Email" required=""/>
        <input type="password" ref={con_mot_de_passe} placeholder="mot de passe " required=""/>
        <button className='btn_connect_candidat' onClick={connect}>Connexion</button>
      </div>
    </div>
  </div>
        </div>
        <Footer/>
        </>
    );
}
export default Connect_candidat;