import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../component/s_nav.css';
function S_nav2 ()
{
  function check_con()
  {
    let vst=JSON.parse(localStorage.getItem("con"));
    console.log(vst.visiteur);
      if(vst.visiteur==="candidat")
      {
        
        var divsToHide = document.getElementsByClassName("nav_recru"); //divsToHide is an array
        for(var i = 0; i < divsToHide.length; i++){
           // divsToHide[i].style.visibility = "hidden"; // or
            divsToHide[i].style.display = "none"; // depending on what you're doing
        }
      }else{
        var divsToHide = document.getElementsByClassName("nav_cand"); //divsToHide is an array
        for(var i = 0; i < divsToHide.length; i++){
           // divsToHide[i].style.visibility = "hidden"; // or
            divsToHide[i].style.display = "none"; // depending on what you're doing
        }
      }
  }
  useEffect(()=>{
    check_con();
  },[])
  
  function déconnecter()
  {
    localStorage.clear();
  }
    return(
          
          <nav className="s_navbar">
           <ul className="s_nav-links">
           <li className='con_email'>{JSON.parse(localStorage.getItem("con")).email}</li>
           <li><Link className='nav_cand' to="/Info_candidat">modifier le profile</Link></li>
           <li><Link className='nav_cand' to="/Ma_postulation">mes postulations</Link></li>
           <li><Link className='nav_recru' to="/Info_recruteur">modifier profile</Link></li>
           <li><Link className='nav_recru' to="/Ajouter_une_annonce2">Ajouter une annonce</Link></li>
           <li><Link className='nav_recru' to="/Mes_annances">mes annances</Link></li>
             <li className="decon_email"><a href="/" onClick={déconnecter}>déconnecter</a></li>
           </ul>
           
          </nav>
        );
    
}
export default S_nav2;
