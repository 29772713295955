import { useRef ,useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import React from 'react';
import './administration.css';
import S_nav from './s_nav';

function Update_annonce()
{
    let { id } = useParams();
    var ob;
    const [annonce__,setAnnonce__]=useState({id_admin:"",poste:"",description:"",email:"",tel:"",ville:"",prix:"",date_de_publication:"",contrat:"",dernier_delai:""});
    async function afficher()
    {
      await fetch(`https://api-careercruiser.onrender.com/annonce/${id}`)
      .then(response => response.json())
      .then(data => ob = data);
      if(annonce__==undefined||annonce__.poste=="")
      {
          setAnnonce__(ob);  
      }
      
    }
    async function update_annonce()
    {
      var noveau_annonce={};
      if(_post_.current.value!=="")
      {
        const poste=_post_.current.value;
        noveau_annonce={...noveau_annonce,...{poste}};
        console.log(noveau_annonce);
      }
      if(_description.current.value!=="")
      {
        const description=_description.current.value;
        noveau_annonce={...noveau_annonce,...{description}};
        console.log(noveau_annonce);
      }
      if(_email.current.value!=="")
      {
        const email =_email.current.value;
        noveau_annonce={...noveau_annonce,...{email}};
        console.log(noveau_annonce);
      }
      if(_tel.current.value!=="")
      {
        const tel =_tel.current.value;
        noveau_annonce={...noveau_annonce,...{tel}};
        console.log(noveau_annonce);
      }
      if(_ville.current.value!=="")
      {
        const ville =_ville.current.value;
        noveau_annonce={...noveau_annonce,...{ville}};
        console.log(noveau_annonce);
      }
      if(_prix.current.value!=="")
      {
        const prix=_prix.current.value;
        noveau_annonce={...noveau_annonce,...{prix}};
        console.log(noveau_annonce);
      }
      if(_contrat.current.value!=="")
      {
        const contrat=_contrat.current.value;
        noveau_annonce={...noveau_annonce,...{contrat}};
        console.log(noveau_annonce);
      }
      if(_dernier_delai.current.value!=="")
      {
        const dernier_delai=_dernier_delai.current.value;
        noveau_annonce={...noveau_annonce,...{dernier_delai}};
        console.log(noveau_annonce);
      }


      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(noveau_annonce)
         };
      const response = await fetch(`https://api-careercruiser.onrender.com/annonce/${id}`, requestOptions);
      const data = await response.json();
    }
    afficher();
  useEffect(()=>{
    afficher();
    console.log(annonce__);
  },[annonce__])

    /******************************************* */

  const _post_=useRef();
  const _description=useRef();
  const _email =useRef();
  const _tel =useRef();
  const _ville =useRef();
  const _prix=useRef();
  const _contrat=useRef();
  const _dernier_delai=useRef();
    
    return(<>
        <div className="parent_">
            <div className="div1_"><S_nav/> </div>
            <div className="div2_"> 
            <div className="card">
      <h2>modifier les info d'annonce</h2>
      <div className="row">
       
          <div className="form-group">
            <label>poste</label>
            <input ref={_post_} placeholder={annonce__.poste} required type="text"/>
          </div>
          <div className="form-group">
            <label>contrat</label>
            <input ref={_contrat} placeholder={annonce__.contrat} type="text"/>
          </div>
          <div className="form-group">
            <label>salaire</label>
            <input ref={_prix} placeholder={annonce__.prix} type="number" min={0} aria-valuenow={0}/>
          </div>
          <div className="form-group">
            <label>lieu</label>
            <input ref={_ville} placeholder={annonce__.ville} type="text"/>
          </div>
          <div className="form-group">
            <label>Email</label>
            <input ref={_email} placeholder={annonce__.email} type="email"/>
          </div>
          <div className="form-group">
            <label>tel</label>
            <input ref={_tel} placeholder={annonce__.tel} type="text"/>
          </div>
          <div className="form-group">
            <label>dernier delai</label>
            <input ref={_dernier_delai} placeholder={annonce__.dernier_delai} type="text"/>
          </div>
          <div className="form-group">
            <label>description</label>
            <textarea ref={_description} placeholder={annonce__.description}></textarea>
          </div>
        <div className="form-group_f">
          <button onClick={update_annonce} >Sauvegarder les modifications</button>  
        </div>
        
      </div>
    </div>
            </div>
        </div>
        </>
        );
    
}
export default Update_annonce;