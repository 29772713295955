//Mes_annances_
import React, { useState } from 'react';
import { useEffect } from 'react';
import S_nav from './s_nav';
function Mes_annances_()
{
    var ob;
  const [postulation,setPostulation]=useState([{id_admin:"",poste:"",description:"",email:"",tel:"",ville:"",prix:"",date_de_publication:"",contrat:"",dernier_delai:""}]);
  let vst=JSON.parse(localStorage.getItem("con"));
    async function afficher()
    {
    await fetch(`https://api-careercruiser.onrender.com/annonce/recru/${vst.ID_ADM}`)
    .then(response => response.json())
    .then(data => ob = data);
    if(postulation===undefined||postulation[0].poste==="")
    {
        setPostulation(ob);  
    }
    }
    useEffect(()=>{
        afficher();
        console.log(postulation);
        },[postulation])
        
        //--------------------------------------------

        function selection_annonce(id_a)
        {
            window.location.href=`infoannonce/${id_a}`;
        }

        //--------------------------------------------
        
        function sup_annonce(id_a)
        {
            fetch(`https://api-careercruiser.onrender.com/annonce/${id_a}`, { method: 'DELETE' })
            .then(() => this.setState({ status: 'Delete successful' }));
            window.location.reload();
        }

        //--------------------------------------------------------
        
        function afficher_candidats(id_a)
        {
           window.location.href=`Liste_candidat__postuler/${id_a}`;
        }
    return(<>
    <div class="parent_">
    <div class="div1_"><S_nav/> </div>
        <div class="div2_">
        <div  className='upd_c'>
      <h1>Postulation</h1>
    <table class="fl-table">
        <thead>
            <tr>
                <th>poste</th>
                <th>ville</th>
                <th>contrat</th>
                <th>salaire</th>
                <th>date publication</th>
                <th>nombre de postulation</th>
                <th></th>
            </tr>
            {postulation.map(postu=>( <tr>
                <td>{postu.poste}</td>
                <td>{postu.ville}</td>
                <td>{postu.contrat}</td>
                <td>{postu.prix}</td>
                <td>{postu.date_de_publication}</td>
                <td>__</td>
                <td><button onClick={()=>selection_annonce(postu._id)}>afficher l'annonce</button><button onClick={()=>afficher_candidats(postu._id)}>afficher candidats</button><button onClick={()=>sup_annonce(postu._id)}>supprimer</button></td>
            </tr>))}
        </thead>
           
            
    </table>
    </div>
        </div>
    </div>
    </>
        );
}
export default Mes_annances_;