import React from 'react';
import Footer from './footer';

function About()
{
    return(<>
    <div style={{height:"100vh",padding:"10vh 4%"}}>
    Bienvenue sur notre site de recherche d'emploi! Notre plateforme est conçue pour connecter les chercheurs d'emploi avec les employeurs de manière fluide et conviviale. Que vous soyez un chercheur d'emploi à la recherche de nouvelles opportunités ou un employeur à la recherche de candidats talentueux, notre site Web offre une variété de fonctionnalités pour vous aider à atteindre vos objectifs.<br/>
Avec nos outils de publication et de recherche d'emploi faciles à utiliser, trouver le bon emploi ou le bon candidat n'a jamais été aussi facile. Nous nous efforçons de rendre le processus de recherche d'emploi sans stress et efficace pour tous les utilisateurs. <br/>
Au cœur de notre mission, nous sommes engagés à promouvoir la diversité, l'équité et l'inclusion sur le lieu de travail. Nous croyons que tout le monde devrait avoir un accès égal aux opportunités d'emploi, et notre site Web reflète cette philosophie.<br/>
Nous vous remercions d'avoir choisi notre site de recherche d'emploi. Nous sommes impatients de vous aider à atteindre vos objectifs de carrière!<br/>
    </div>
    <Footer/>
    </>
        );
    
}
export default About;