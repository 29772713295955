import './App.css';
import Nav from './component/nav';
import About from './component/about';
import List_annonce from './component/liste_annonce';
import Info_annonce from './component/info_annonce';
import Connect from './component/connect';
import store_ from './redux/stor';
import Inscription from './component/ajouter_nouvel_admin';
import Liste_des_admin from './component/liste_des_admin';
import Liste_des_annonces from './component/liste_des_annonces';
import Ajouter_une_annonce from './component/ajouter_une_annonce';
import Update_admin from './component/updat_admin';
import { Provider } from 'react-redux';
import { BrowserRouter,Route,Router,Routes } from 'react-router-dom';
import Update_annonce from './component/update_annonce';
import Liste_des_candidat from './component/liste_des_candidat';
import Update_candidat from './component/updat_candidat';
import Connect_candidat from './component/connect_candidat';
import Liste_des_recruteurs from './component/liste_des_recruteur';
import Update_recrecruteur from './component/Update_recruteur';
import Info_candidat from './component/info_candidat';
import Footer from './component/footer';
import Ma_postulation from './component/ma_postulation';
import Info_recruteur from './component/info_recruteur';
import Mes_annances from './component/mes_annances';
import Mes_annances_ from './component/Mes_annances_';
import Ajouter_une_annonce2 from './component/Ajouter_une_annonce2';
import Liste_candidat__postuler from './component/liste_candidat__postuler';
import Profil_condidat from './component/profil_condidat'; 
import Profil_condidat_ from './component/Profil_condidat_';
function App() {
  return (
    <div className="App">
      <Provider store={store_}>
       <BrowserRouter>
        <>
          <Nav/>
          <Routes>
          <Route path="/" Component={List_annonce}></Route>
          <Route path='/infoannonce/:id_a' Component={Info_annonce}/>
          <Route path='Connect' Component={Connect}/>
          <Route path='inscription' Component={Inscription}/>
          <Route path='/about' Component={About}/>
          <Route path='Liste_des_admin' Component={Liste_des_admin}/>
          <Route path='Liste_des_annonces' Component={Liste_des_annonces}/>
          <Route path='Ajouter_une_annonce' Component={Ajouter_une_annonce}/>
          <Route path='Update_admin/:id' Component={Update_admin}/>
          <Route path='Update_annonce/:id' Component={Update_annonce}/>
          <Route path='Liste_des_candidats' Component={Liste_des_candidat}/>
          <Route path='Update_candidat/:id' Component={Update_candidat}/>
          <Route path='Connect_candidat' Component={Connect_candidat}/>
          <Route path='Liste_des_recruteurs' Component={Liste_des_recruteurs}/>
          <Route path='Update_recruteur/:id' Component={Update_recrecruteur}/>
          <Route path='Info_candidat' Component={Info_candidat}/>
          <Route path='Ma_postulation' Component={Ma_postulation}/>
          <Route path='Info_recruteur' Component={Info_recruteur}/>
          <Route path='Mes_annances' Component={Mes_annances}/>
          <Route path='Ajouter_une_annonce2' Component={Ajouter_une_annonce2}/>
          <Route path='Liste_candidat__postuler/:id' Component={Liste_candidat__postuler}/>
          <Route path='Profil_condidat/:id' Component={Profil_condidat}/>
          <Route path='Profil_condidat_/:id' Component={Profil_condidat_}/>
          <Route path='Mes_annances_' Component={Mes_annances_}/>
          </Routes>
          {/* <Footer/> */}
        </>
      </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
