import React, { useState } from 'react';
import { useEffect,useRef } from 'react';
import S_nav from './s_nav';
import { useParams } from 'react-router-dom';
function Update_recrecruteur()
{
  let { id } = useParams();
  const [recruteur,setrecruteur]=useState({nom:"",prenom:"",adresse:"",email:"",tel:"",mot_de_passe:"",genre:"",date_de_naissance:"",niveau_etude:"",photo_url:"",autorisation:""});
  var ob={};
  async function afficher()
  {
    await fetch(`https://api-careercruiser.onrender.com/recruteur/${id}`)
    .then(response => response.json())
    .then(data => ob = data);
    if(recruteur==undefined||recruteur.nom=="")
    {
      setrecruteur(ob);
    }
  }
    useEffect(() => {
      afficher();   
    }, [recruteur]);
    const _nom =useRef();
    const _prenom=useRef();
    const _adresse=useRef();
    const _email=useRef();
    const _mot_de_passe=useRef();
    const _mot_de_passe_=useRef();
    const _tel=useRef();
    const _domain=useRef();
    const _description=useRef();
    const _nom_entreprise=useRef();
    const _photo_url=useRef();
   
    async function update_recrecruteurupdate_recrecruteur()
    {
      var noveau_recruteur_info={};
      
      //_photo_url
      if(_photo_url.current.value!=="")
      {
        const photo_url=_photo_url.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{photo_url}};
        console.log(noveau_recruteur_info);
      }
      if(_nom.current.value!=="")
      {
        const nom=_nom.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{nom}};
        console.log(noveau_recruteur_info);
      }
      if(_prenom.current.value!=="")
      {
        const prenom=_prenom.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{prenom}};
        console.log(noveau_recruteur_info);
      }
      if(_tel.current.value!=="")
      {
        const tel=_tel.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{tel}};
        console.log(noveau_recruteur_info);
      }
      if(_adresse.current.value!=="")
      {
        const adresse=_adresse.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{adresse}};
        console.log(noveau_recruteur_info);
      }
      if(_domain.current.value!=="")
      {
        const domain=_domain.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{domain}};
        console.log(noveau_recruteur_info);
      }
      if(_description.current.value!=="")
      {
        const description=_description.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{description}};
        console.log(noveau_recruteur_info);
      }
      if(_nom_entreprise.current.value!=="")
      {
        const nom_entreprise=_nom_entreprise.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{nom_entreprise}};
        console.log(noveau_recruteur_info);
      }
      if(_email.current.value!=="")
      {
        const email=_email.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{email}};
        console.log(noveau_recruteur_info);
      }
      if(_mot_de_passe.current.value!=="")
      {
        if(_mot_de_passe.current.value===_mot_de_passe_.current.value)
        {
          const mot_de_passe=_mot_de_passe.current.value;
          noveau_recruteur_info={...noveau_recruteur_info,...{mot_de_passe}};
          console.log(noveau_recruteur_info);
        }else{
          alert("Vérifiez le mot de passe");
        }
      }
     
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(noveau_recruteur_info)
         };
      const response = await fetch(`https://api-careercruiser.onrender.com/recruteur/${id}`, requestOptions);
      const data = await response.json();
    }
    return(<>
    <div class="parent_">
    <div class="div1_"><S_nav/> </div>
        <div class="div2_">
    <div className='form_wrapper_'>
    <div className="form_wrapper">
  <div className="form_container">
    <div className="title_container">
      <h2>Modifier les informations de la recruteur</h2>
    </div>
    <div className="row clearfix">
      <div className="">
        <form>
        <div className="row clearfix">
        <div className="col_half">
              <div className="input_field">
              <label>photo_url</label>
                <input type="text" ref={_photo_url} placeholder={recruteur.photo_url} required />
              </div>
            </div>
            <div className="col_half">
              <div className="input_field">
              <label>prenom</label>
                <input type="text" ref={_prenom}  placeholder={recruteur.prenom} />
              </div>
            </div>
            <div className="col_half">
              <div className="input_field">
              <label>nom</label>
                <input type="text" ref={_nom} placeholder={recruteur.nom} required />
              </div>
            </div>
          </div>
          <div className="input_field">
          <label>nom d'entreprise</label>
            <input type="text" ref={_nom_entreprise} placeholder={recruteur.nom_entreprise} required />
          </div>
          <div className="input_field">
          <label>tel</label>
            <input type="text" ref={_tel} placeholder={recruteur.tel} required />
          </div>
          <div className="input_field"> 
          <label>adresse</label>
            <input type="text" ref={_adresse} placeholder={recruteur.adresse} required />
          </div>
          <div className="input_field"> 
          <label>email</label>
            <input type="email" ref={_email} placeholder={recruteur.email} required />
          </div>
          <div className="input_field"> 
          <label>domain</label>
            <input type="password" ref={_domain} placeholder={recruteur.domain} required />
          </div>
          <div className="input_field"> 
          <label>description</label>
            <input type="password" ref={_description} placeholder={recruteur.description} required />
          </div>
          <div className="input_field"> 
          <label>mot de passe</label>
            <input type="password" ref={_mot_de_passe} placeholder={recruteur.mot_de_passe} required />
          </div>
          <div className="input_field"> 
          <label>confirmer mot de passe</label>
            <input type="password" ref={_mot_de_passe_} placeholder={recruteur.mot_de_passe} required />
          </div>

          <a className='reg_btn' onClick={update_recrecruteurupdate_recrecruteur} >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            enregistrer la modification
        </a>  
        </form>
      </div>
    </div>
  </div>
</div>
    </div>
        </div>
    </div>
    </>
        );
}
export default Update_recrecruteur;