import React,{useEffect,useState} from 'react';
import './administration.css';
import S_nav from './s_nav';
import Tr_candidat from './tr_candidat';

function Liste_des_candidat ()
{    
  var ob;
  const [candidat,setCandidat]=useState([{nom:"",prenom:"",adresse:"",email:"",tel:"",mot_de_passe:"",genre:"",date_de_naissance:"",niveau_etude:"",autorisation:""}]);
      async function afficher()
  {
    await fetch('https://api-careercruiser.onrender.com/candidat')
    .then(response => response.json())
    .then(data => ob = data);
    if(candidat==undefined||candidat[0].nom=="")
    {
        setCandidat(ob);  
    }
    
  }
      useEffect(()=>{
      afficher();
      },[candidat])
    return(<>
    <div class="parent_">
        <div class="div1_"><S_nav/> </div>
        <div class="div2_"> 
<div class="table-wrapper">
    <table class="fl-table">
        <thead>
            <tr>
                <th>nom</th>
                <th>prenom</th>
                <th>tel</th>
                <th>email</th>
                <th>genre</th>
                <th>date de naissance</th>
                <th>niveau d'etude</th>
                <th>autorisation</th>
                <th></th>
            </tr>
        </thead>
            {candidat.map((candid)=>(<Tr_candidat id_={candid._id} nom={candid.nom} prenom={candid.prenom}  tel={candid.tel} email={candid.email} genre={candid.genre} date_de_naissance={candid.date_de_naissance} niveau_etude={candid.niveau_etude} autorisation={candid.autorisation} />))}
    </table>
</div>

        </div>
    </div>
    </>
        );
    
}
export default Liste_des_candidat;