import React from 'react';


function Tr_candidat(props)
{
    function update_candidat()
    {
        window.location.href=`Update_candidat/${props.id_}`;
    }
    function sup_annonce()
    {
        fetch(`https://api-careercruiser.onrender.com/candidat/${props.id_}`, { method: 'DELETE' })
        .then(() => this.setState({ status: 'Delete successful' }));
        fetch(`https://api-careercruiser.onrender.com/postuler/${props.id_annonce}`, { method: 'DELETE' })
        .then(() => this.setState({ status: 'Delete all_candidat' }));
        window.location.href="Liste_des_candidats";
    }
    var auto="";
    if(props.autorisation===true)
    {
        auto="autorisée";
    }else{
        auto="bloquée";
    }

    return(
    <tr>
        <td>{props.nom}</td>
        <td>{props.prenom}</td>
        <td>{props.tel}</td>
        <td>{props.email}</td>
        <td>{props.genre}</td>
        <td>{props.date_de_naissance}</td>
        <td>{props.niveau_etude}</td>
        <td>{auto}</td>
        <th><button onClick={update_candidat}>modifier</button><button onClick={sup_annonce}>supprimer</button></th>
    </tr>
        );
    
}
export default Tr_candidat;