import React, { useState } from 'react';
import { useEffect,useRef} from 'react';
import Annonce from './annonce';
import Footer from './footer';
import './list_annonce.css';
import src from './documents-search-icon.svg';

function List_annonce()
{
  const filter_title=useRef();
  const filter_salair=useRef();
  const filter_ville=useRef();
  var ob;
  const [annonce__,setAnnonce__]=useState([{id_admin:"",poste:"",description:"",email:"",tel:"",ville:"",prix:"",date_de_publication:"",contrat:"",dernier_delai:"",photo_url:""}]);
  const [anr,setAnr]=useState([{id_admin:"",poste:"",description:"",email:"",tel:"",ville:"",prix:"",date_de_publication:"",contrat:"",dernier_delai:"",photo_url:""}]);
      async function afficher()
  {
    await fetch('https://api-careercruiser.onrender.com/annonce')
    .then(response => response.json())
    .then(data => ob = data);
    if(annonce__==undefined||annonce__[0].poste=="")
    {
        setAnnonce__(ob);  
        setAnr(ob);  
    }
    
  }
      useEffect(()=>{
      afficher();
      },[annonce__])
      let url="https://cdn.shopify.com/s/files/1/0758/0313/2212/files/Screenshot_20230419_203907_Temu.jpg?v=1682464343&width=1920";
      function filter()
      {
        setAnnonce__(anr.filter(word => word.poste.toLowerCase().includes(filter_title.current.value.toLowerCase())&&word.prix >= filter_salair.current.value&&word.ville.toLowerCase().includes(filter_ville.current.value.toLowerCase())));
      }
    return(<>
            <div class="parent">
              <div class="div1">
              <div id="banner">
    <a href="/">
      <div id="target"></div>
      <img id="product" src={url}/>
      <div id="badge">SAVE NOW!</div>
      <div id="sale">
        <span id="sale-text">30% off All lenovo Products</span><br />
        <span id="button">See Special</span>
      </div>
    </a>
  </div>

              </div>
              <div class="div2"> 
                    {annonce__.map((emploi)=>(<Annonce id_r={emploi.id_admin} Background={emploi.photo_url} id_a={emploi._id} poste={emploi.poste} prix={emploi.prix} contrat={emploi.contrat} lieu={emploi.ville} d_p={emploi.date_de_publication}/>))}
                    </div>
              <div class="div3">
                <div className='p_search-container'>
                Recherche par poste
                <input type="text" ref={filter_title} name="search" onChange={filter} placeholder="Search..." class="search-input"/>
                </div>
                <div className='p_search-container'>
                Recherche par ville <input ref={filter_ville} onChange={filter} type='text'/>      
                </div>
                <div className='p_search-container'>
                Recherche par salaire <input ref={filter_salair} onChange={filter} placeholder='0.DH' type='number'/>      
                </div>
              </div>
            </div> 
            <Footer/>
    </>
        );
    
}
export default List_annonce;