import React,{useRef,useState,useEffect} from 'react';
import S_nav2 from './s_nav2';
import './administration.css';
function Info_candidat()
{   
   const _nom =useRef();
  const _prenom=useRef();
  const _adresse=useRef();
  const _email=useRef();
  const _mot_de_passe=useRef();
  const _mot_de_passe_=useRef();
  const _tel=useRef();
  const _date_de_naissance=useRef();
  const _niveau_etude=useRef();
  const _annee_obtenire=useRef();
  const _filiere=useRef();
  const _note=useRef();
  const _domaine=useRef();
  const _pose=useRef();
  const _domaine_=useRef();
  const _anne_exp=useRef();
  var _genre="";
  let vst=JSON.parse(localStorage.getItem("con"));
  function gr()
  {
    _genre=document.querySelector('input[name="_genre"]:checked').value;
  }
  var ob,ob2,ob3;
  const [candidat,setCandidat]=useState({nom:"",prenom:"",adresse:"",email:"",tel:"",mot_de_passe:"",genre:"",date_de_naissance:"",niveau_etude:"",autorisation:""});
  const [diplome,setDiplome]=useState([{annee_obtenire:"",filiere:"",note:"",domaine:""}]);
  const [experience,setExperience]=useState([{domaine:"",annee_experience:0,poste:""}]);
async function afficher()
{
await fetch(`https://api-careercruiser.onrender.com/candidat/${vst.ID_ADM}`)
.then(response => response.json())
.then(data => ob = data);
if(candidat==undefined||candidat.nom=="")
{
    setCandidat(ob);  
}

}

  useEffect(()=>{
  afficher();
  },[candidat])
  async function afficher_diplom()
{
await fetch(`https://api-careercruiser.onrender.com/diplome/${vst.ID_ADM}`)
.then(response => response.json())
.then(data => ob2 = data);
if(diplome==undefined||diplome[0].annee_obtenire=="")
{
    setDiplome(ob2);  
}

}
useEffect(()=>{
  afficher_diplom();
  },[diplome])
  
  /* ********************************************************************* */

    async function afficher_experience()
{
await fetch(`https://api-careercruiser.onrender.com/experience/${vst.ID_ADM}`)
.then(response => response.json())
.then(data => ob3 = data);
if(experience==undefined||experience[0].poste=="")
{
    setExperience(ob3);  
}

}
useEffect(()=>{
  afficher_experience();
  },[experience])

  /******************************************************************************************************/
  async function update_candidat()
    {
      var noveau_candidat_info={};
      
      if(_nom.current.value!=="")
      {
        const nom=_nom.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{nom}};
        console.log(noveau_candidat_info);
      }
      if(_prenom.current.value!=="")
      {
        const prenom=_prenom.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{prenom}};
        console.log(noveau_candidat_info);
      }
      if(_genre!=="")
      {
        const genre=_genre;
        noveau_candidat_info={...noveau_candidat_info,...{genre}};
        console.log(noveau_candidat_info);
      }
      if(_tel.current.value!=="")
      {
        const tel=_tel.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{tel}};
        console.log(noveau_candidat_info);
      }
      if(_adresse.current.value!=="")
      {
        const adresse=_adresse.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{adresse}};
        console.log(noveau_candidat_info);
      }
      if(_email.current.value!=="")
      {
        const email=_email.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{email}};
        console.log(noveau_candidat_info);
      }
      if(_date_de_naissance.current.value!=="")
      {
        const date_de_naissance=_date_de_naissance.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{date_de_naissance}};
        console.log(noveau_candidat_info);
      }
      if(_niveau_etude.current.value!=="")
      {
        const niveau_etude=_niveau_etude.current.value;
        noveau_candidat_info={...noveau_candidat_info,...{niveau_etude}};
        console.log(noveau_candidat_info);
      }
      if(_mot_de_passe.current.value!=="")
      {
        if(_mot_de_passe.current.value===_mot_de_passe_.current.value)
        {
          const mot_de_passe=_mot_de_passe.current.value;
          noveau_candidat_info={...noveau_candidat_info,...{mot_de_passe}};
          console.log(noveau_candidat_info);
        }else{
          alert("Vérifiez le mot de passe");
        }
      }
     
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(noveau_candidat_info)
         };
      const response = await fetch(`https://api-careercruiser.onrender.com/candidat/${vst.ID_ADM}`, requestOptions);
      const data = await response.json();
    }


    function ajouter_diplom()
    {
      const post={
        annee_obtenire:_annee_obtenire.current.value,
        filiere:_filiere.current.value,
        note:_note.current.value,
        domaine:_domaine.current.value
        };
        console.log(post)
        fetch(`https://api-careercruiser.onrender.com/diplome/${vst.ID_ADM}`,{
              method:"POST",
              headers:{"content-type":"application/json"},
             body:JSON.stringify(post)
            }).then(()=>{
              alert("ajouter avec succès");
            })
            window.location.reload();
    }
    function supprimer_diplom(id)
    {
      fetch(`https://api-careercruiser.onrender.com/diplome/one/${id}`, { method: 'DELETE' })
      .then(() => this.setState({ status: 'Delete successful' }));
      window.location.reload();
    }
    /********************************************* */
    function supprimer_experience(id)
    {
      fetch(`https://api-careercruiser.onrender.com/experience/one/${id}`, { method: 'DELETE' })
      .then(() => this.setState({ status: 'Delete successful' }));
      window.location.reload();
    }
    /*********************************************** */
    function ajouter_experience()
    {
      const post={
          domaine:_domaine_.current.value,
          annee_experience:_anne_exp.current.value,
          poste:_pose.current.value
        };
        fetch(`https://api-careercruiser.onrender.com/experience/${vst.ID_ADM}`,{
              method:"POST",
              headers:{"content-type":"application/json"},
             body:JSON.stringify(post)
            }).then(()=>{
              alert("ajouter avec succès");
            })
            window.location.reload();
    }
    return(<>
    <div class="parent_">
    <div class="div1_">
      <S_nav2/>
    </div>
        <div class="div2_2">
        <div class="signup upd">
      <div>
        <h1>&nbsp;&nbsp;&nbsp; Information personnel</h1>
        <span className='rdyo'>
        nom
          <input type="text" ref={_nom}  placeholder={candidat.nom} required=""/>
        </span>
        <span className='rdyo'>
        prenom
          <input type="text" ref={_prenom}  placeholder={candidat.prenom} required=""/>
        </span>
        <span className='rdyo'>
        adresse
          <input type="text" ref={_adresse}  placeholder={candidat.adresse} required=""/>
        </span>
        <span className='rdyo'>
        email
         <input type="email" ref={_email}  placeholder={candidat.email} required=""/>
        </span>
        <span className='rdyo'>
        mot de passe
          <input type="password" ref={_mot_de_passe}  placeholder={candidat.mot_de_passe} required=""/>
        </span>
        <span className='rdyo'>
        confirmer mot de passe
        <input type="password" ref={_mot_de_passe_}  placeholder={candidat.mot_de_passe} required=""/>
        </span>
        <span className='rdyo'>
        tel
         <input type="text" ref={_tel}  placeholder={candidat.tel} required=""/>
        </span>
        <span className='rdyo' id='rpls'>
        date de naissance
          <input type="text" ref={_date_de_naissance}  placeholder={candidat.date_de_naissance} required=""/>
        </span>
        <span className='rdyo' id='candidat'>
        niveau d'étude
          <input type="text" ref={_niveau_etude}  placeholder={candidat.niveau_etude} required=""/>
        </span>
        <span className='rdyo' >
        mâle<input type="radio" onClick={gr}  name="_genre" value="mâle" />
        femelle<input type="radio" onClick={gr} name="_genre" value="femelle"  /> 
        </span>
        <button onClick={update_candidat} >enregistrer la modification</button>
      </div>
    </div>
    <div  className='upd_c'>
      <h1>Diplome</h1>
    <table class="fl-table">
        <thead>
            <tr>
                <th>filiere</th>
                <th>note</th>
                <th>date d'obtenir</th>
                <th>domaine</th>
                <th></th>
            </tr>
            {
              diplome.map(dip=>( <tr>
                <td>{dip.filiere}</td>
                <td>{dip.note}</td>
                <td>{dip.annee_obtenire}</td>
                <td>{dip.domaine}</td>
                <th><button onClick={()=>supprimer_diplom(dip._id)} >supprimer</button></th>
            </tr>))
            }
            <tr>
        <td><input ref={_filiere} className='dip_upp'  type='text'></input></td>
        <td><input ref={_note} className='dip_upp' max="20" min="10" defaultValue="10" type='number'></input></td>
        <td><input ref={_annee_obtenire} className='dip_upp' placeholder='jj-mm-aaaa' type='text'></input></td>
        <td><input ref={_domaine} className='dip_upp' placeholder='Finance,Marketing,Informatique'  type='text'></input></td>
        <th><button onClick={ajouter_diplom} >ajouter</button></th>
    </tr>
        </thead>
           
            
    </table>
    </div>
    <div className='upd_c'>
      <h1>Experience</h1>
      <table class="fl-table">
        <thead>
            <tr>
                <th>poste</th>
                <th>domaine </th>
                <th>annee experience</th>
                <th></th>
            </tr>
            {
              experience.map(expi=>( <tr>
                <td>{expi.poste}</td>
                <td>{expi.domaine}</td>
                <td>{expi.annee_experience}</td>
                <th><button onClick={()=>supprimer_experience(expi._id)} >supprimer</button></th>
            </tr>))
            }
            <tr>
        <td><input ref={_pose} className='dip_upp'  type='text'></input></td>
        <td><input ref={_domaine_} className='dip_upp'  type='text'></input></td>
        <td><input ref={_anne_exp} className='dip_upp' placeholder='EX: 20 jours || année || mois' type='text'></input></td>
        <th><button onClick={ajouter_experience} >ajouter</button></th>
    </tr>
        </thead>
           
            
    </table>
    </div>
        </div>
    </div>
    </>
        );
    
}
export default Info_candidat;