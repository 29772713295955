import React, { useState } from 'react';
import { useRef,useEffect } from 'react';
import S_nav2 from './s_nav2';
import { useParams } from 'react-router-dom';
import emailjs from 'emailjs-com';
function Liste_candidat__postuler()
{
    let { id } = useParams();
    var ob,obb;
    var count=0;
    const [can_info,setCan_info]=useState([]);
    const [annonce,setAnnonce]=useState();
    const [recruteur,setRecruteur]=useState();
    const [post,setPost]=useState([]);
  let vst=JSON.parse(localStorage.getItem("con"));

  //---------------------------------------------------------

  async function afficher3()
  {
  await fetch(`https://api-careercruiser.onrender.com/annonce/${id}`)
  .then(response => response.json())
  .then(data => setAnnonce(data));
  }
  async function afficher4()
  {
  await fetch(`https://api-careercruiser.onrender.com/recruteur/${annonce.id_admin}`)
  .then(response => response.json())
  .then(data => setRecruteur(data));
  }

  async function afficher2()
  {
  await fetch(`https://api-careercruiser.onrender.com/postuler/annonce/${id}`)
  .then(response => response.json())
  .then(data => setPost(data));
  }
  useEffect(()=>{
      afficher2();
      },[])
      useEffect(()=>{
        afficher3();
        afficher4();
        },[annonce,recruteur])
      useEffect(()=>{
          for(let i in post)
          {
            afficher(i);
          }
        },[post])
        useEffect(()=>{
          if(can_info.length > post.length)
          {
            setCan_info(can_info.filter((value, index) => {return index < post.length}))
          }
        },[can_info])
  //-------------------------------------------------------------

  function profil_candidat(id)
  {
    window.location.pathname=`Profil_condidat/${id}`
  }
  async function afficher(x)
  {
        await fetch(`https://api-careercruiser.onrender.com/candidat/${post[x].id_candidat}`)
        .then(response => response.json())
        .then(data =>setCan_info(can_info=>[...can_info,{...post[x],...data}]));
        
  }   
  const sendEmail = (nom,prenom,email) => {
    emailjs.send(
      'service_n3kjanv', // Replace with your service ID
      'template_7ew28po', // Replace with your template ID
      {
          Nom_du_candidat: `${nom} ${prenom}`,
          Intitule_du_poste: annonce.poste,
          Nom_de_entreprise: recruteur.nom_entreprise,
          email_entreprise: recruteur.email,
          to_email: email // Replace with the recipient's email address
        // Add other email parameters as needed
      },
      '4hiFMNEAUx2sCuTaX' // Replace with your user ID
    )
      .then((response) => {
        console.log('Email sent successfully!', response);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };
    return(<>
    <div className="parent_">
    <div className="div1_"><S_nav2/> </div>
        <div className="div2_">
        <div  className='upd_c'>
      <h1>candidat</h1>
    <table className="fl-table">
        <thead>
            <tr>
                <th>nom</th>
                <th>prenom</th>
                <th>tel</th>
                <th>email</th>
                <th>niveau etude</th>
                <th>date de postulation</th>
                <th></th>
            </tr>
            {can_info.map((postu)=>( <tr>
                <td>{postu.nom}</td>
                <td>{postu.prenom}</td>
                <td>{postu.tel}</td>
                <td>{postu.email}</td>
                <td>{postu.niveau_etude}</td>
                <td>{postu.date_postulation}</td>
                <td><button onClick={()=>profil_candidat(postu.id_candidat)} >afficher profile candidat</button><button onClick={()=>sendEmail(postu.nom,postu.prenom,postu.email)} >invité</button></td>
            </tr>))}
        </thead>
           
            
    </table>
    </div>
        </div>
    </div>
    </>
        );
}
export default Liste_candidat__postuler;