import React,{useEffect,useState} from 'react';
import './administration.css';
import S_nav from './s_nav';
import Tr_recruteur from './tr_recruteur';

function Liste_des_recruteurs ()
{    
  var ob;
  const [recruteur,setrecruteur]=useState([{
    nom:"",
    prenom:"",
    adresse:"",
    email:"",
    tel:"",
    mot_de_passe:"",
    domain:"",
    description:"",
    nom_entreprise:"",
    autorisation:""}]);

      async function afficher()
  {
    await fetch('https://api-careercruiser.onrender.com/recruteur')
    .then(response => response.json())
    .then(data => ob = data);
    if(recruteur==undefined||recruteur[0].nom=="")
    {
        setrecruteur(ob);  
    }
    
  }
      useEffect(()=>{
      afficher();
      },[recruteur])
    return(<>
    <div class="parent_">
        <div class="div1_"><S_nav/> </div>
        <div class="div2_"> 
<div class="table-wrapper">
    <table class="fl-table">
        <thead>
            <tr>
                <th>nom</th>
                <th>prenom</th>
                <th>tel</th>
                <th>email</th>
                <th>nome de société</th>
                <th>domain</th>
                <th>autorisation</th>
                <th></th>
            </tr>
        </thead>
            {recruteur.map((recru)=>(<Tr_recruteur id_={recru._id} nom={recru.nom} prenom={recru.prenom}  tel={recru.tel} email={recru.email} nom_entreprise={recru.nom_entreprise} domain={recru.domain} autorisation={recru.autorisation} />))}
    </table>
</div>

        </div>
    </div>
    </>
        );
    
}
export default Liste_des_recruteurs;