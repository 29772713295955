import React from 'react';
import '../component/nav.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Nav ()
{
  useEffect((e)=>{
    if(JSON.parse(localStorage.getItem("con"))==null)
    {
      document.getElementById("Connexion").innerHTML="Connexion";
      document.getElementById("conn").style.display = "none";
    }else
    {
      let vst=JSON.parse(localStorage.getItem("con"));
      if((vst.visiteur === "candidat")||(vst.visiteur === "recruteur"))
      {
        
        document.getElementById("Connexion").innerHTML="profil";
        if(vst.visiteur === "candidat")
        {
          document.getElementById("Connexion").setAttribute("href", "/Info_candidat");
        }else{
          document.getElementById("Connexion").setAttribute("href", "/Info_recruteur");
        }
      }else if(vst.visiteur=== "admin"){
        document.getElementById("Connexion").style.display = "none";
        document.getElementById("conn").style.display = "block"
      }else{
        console.log("???**************");
        document.getElementById("conn").style.display = "block";
      }
    }
    //document.getElementById("conn").disabled = true;
  //  document.getElementById("conn").style.display = "none";
},[])
    return(
          <nav className="navbar">
           <a href="/" className="logo"><h3>Career<span>Cruiser</span></h3></a>
           <ul className="nav-links">

            {/* <li><a href="/">Home</a></li>
             <li><a href="/about">About</a></li>
             <li id="Connexion_"><a id='Connexion' href="Connect_candidat">Connexio</a></li>
             <li id="conn"><a href='Ajouter_une_annonce'>administration</a></li> */}

             <li><Link to="/">Home</Link></li>
             <li><Link to="/about">About</Link></li>
             <li id="Connexion_"><a id='Connexion' href="Connect_candidat">Connexio</a></li>
             <li id="conn"><Link to="Ajouter_une_annonce">administration</Link></li>
           </ul>
           <div className="burger">
             <div className="line"></div>
             <div className="line"></div>
             <div className="line"></div>
           </div>
          </nav>
        );
    
}
export default Nav;
