import React, { useState } from 'react';
import { useRef,useEffect } from 'react';
import './administration.css';
import S_nav from './s_nav';
import { useParams } from 'react-router-dom';
function Update_admin()
{
  let { id } = useParams();
  var [admin,setAdmin]=useState({nom:"",prenom:"",adresse:"",
  email:"",mot_de_passe:"",mot_de_passe_:"",tel:"",_id:""});
  var ob={};
  async function afficher()
  {
    await fetch(`https://api-careercruiser.onrender.com/admin/${id}`)
    .then(response => response.json())
    .then(data => ob = data);
    if(admin==undefined||admin.nom=="")
    {
      setAdmin(ob);
    }
  }
    useEffect(() => {
      afficher();  
      console.log(admin)    
    }, [admin]);
    
    let { id_admin } = useParams();
    const _nom =useRef();
    const _prenom=useRef();
    const _adresse=useRef();
    const _email=useRef();
    const _mot_de_passe=useRef();
    const _mot_de_passe_=useRef();
    const _tel=useRef();

    async function update_admin()
    {
      var noveau_admin_info={};
      
      if(_nom.current.value!=="")
      {
        const nom=_nom.current.value;
        noveau_admin_info={...noveau_admin_info,...{nom}};
        console.log(noveau_admin_info);
      }
      if(_prenom.current.value!=="")
      {
        const prenom=_prenom.current.value;
        noveau_admin_info={...noveau_admin_info,...{prenom}};
        console.log(noveau_admin_info);
      }
      if(_adresse.current.value!=="")
      {
        const adresse=_adresse.current.value;
        noveau_admin_info={...noveau_admin_info,...{adresse}};
        console.log(noveau_admin_info);
      }
      if(_nom.current.value!=="")
      {
        const email=_email.current.value;
        noveau_admin_info={...noveau_admin_info,...{email}};
        console.log(noveau_admin_info);
      }
      if(_mot_de_passe.current.value!=="")
      {
        if(_mot_de_passe.current.value===_mot_de_passe_.current.value)
        {
          const mot_de_passe=_mot_de_passe.current.value;
          noveau_admin_info={...noveau_admin_info,...{mot_de_passe}};
          console.log(noveau_admin_info);
        }else{
          alert("Vérifiez le mot de passe");
        }
      }
      if(_tel.current.value!=="")
      {
        const tel=_tel.current.value;
        noveau_admin_info={...noveau_admin_info,...{tel}};
        console.log(noveau_admin_info);
      }
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(noveau_admin_info)
         };
      const response = await fetch(`https://api-careercruiser.onrender.com/admin/${id}`, requestOptions);
      const data = await response.json();
    }
    return(<>
    <div class="parent_">
    <div class="div1_"><S_nav/> </div>
        <div class="div2_">
    <div className='form_wrapper_'>
    <div className="form_wrapper">
  <div className="form_container">
    <div className="title_container">
      <h2>Modifier les informations de l'administrateur</h2>
    </div>
    <div className="row clearfix">
      <div className="">
        <form>
        <div className="row clearfix">
            <div className="col_half">
              <div className="input_field">
              <label>prenom</label>
                <input type="text" ref={_prenom}  placeholder={admin.prenom} />
              </div>
            </div>
            <div className="col_half">
              <div className="input_field">
              <label>nom</label>
                <input type="text" ref={_nom} placeholder={admin.nom} required />
              </div>
            </div>
          </div>
          <div className="input_field">
          <label>tel</label>
            <input type="text" ref={_tel} placeholder={admin.tel} required />
          </div>
          <div className="input_field"> 
          <label>adresse</label>
            <input type="text" ref={_adresse} placeholder={admin.adresse} required />
          </div>
          <div className="input_field"> 
          <label>email</label>
            <input type="email" ref={_email} placeholder={admin.email} required />
          </div>
          <div className="input_field"> 
          <label>mot de passe</label>
            <input type="password" ref={_mot_de_passe} placeholder={admin.mot_de_passe} required />
          </div>
          <div className="input_field"> 
          <label>confirmer mot de passe</label>
            <input type="password" ref={_mot_de_passe_} placeholder={admin.mot_de_passe} required />
          </div>
          
          <a className='reg_btn' onClick={update_admin} >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            enregistrer la modification
        </a>  
        </form>
      </div>
    </div>
  </div>
</div>
    </div>
        </div>
    </div>
    </>
        );
}
export default Update_admin;