import React,{useEffect,useState} from 'react';
import './administration.css';
import S_nav from './s_nav';
import Tr_admin from './tr_admin';

function Liste_des_admin ()
{    
  var ob;
  const [admin_,setAdmin_]=useState([{nom:"",prenom:"",adresse:"",
  email:"",mot_de_passe:"",mot_de_passe_:"",tel:"",_id:""}]);
      async function afficher()
  {
    await fetch('https://api-careercruiser.onrender.com/admin')
    .then(response => response.json())
    .then(data => ob = data);
    if(admin_==undefined||admin_[0].nom=="")
    {
        setAdmin_(ob);  
    }
    
  }
      useEffect(()=>{
      afficher();
      },[admin_])
    return(<>
    <div class="parent_">
        <div class="div1_"><S_nav/> </div>
        <div class="div2_"> 
<div class="table-wrapper">
    <table class="fl-table">
        <thead>
            <tr>
                <th>nom</th>
                <th>prenom</th>
                <th>tel</th>
                <th>email</th>
                <th>adresse</th>
                <th>mot de passe</th>
                <th></th>
            </tr>
        </thead>
            {admin_.map((adm)=>(<Tr_admin id_admin={adm._id} nom={adm.nom} prenom={adm.prenom} tel={adm.tel} email={adm.email} adresse={adm.adresse} mot_de_passe={adm.mot_de_passe}/>))}
    </table>
</div>

        </div>
    </div>
    </>
        );
    
}
export default Liste_des_admin;