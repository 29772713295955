import React from 'react';

function Tr_admin(props)
{
    function updat_admin()
    {
        window.location.href=`Update_admin/${props.id_admin}`;
    }
    
    return(
        <tr>
        <td>{props.nom}</td>
        <td>{props.prenom}</td>
        <td>{props.tel}</td>
        <td>{props.email}</td>
        <td>{props.adresse}</td>
        <td>{props.mot_de_passe}</td>
        <th><button onClick={updat_admin}>modifier</button><button>supprimer</button></th>
    </tr>
        );
    
}
export default Tr_admin;