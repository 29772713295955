import { ALLA,ID_A ,ALL_UTI,ALL_ADM} from "../actions/action";


const annonce=[{
  id_annonce:"a1",
  id_recruteur:"r2",
  poste:"poste teste",
  description:"description teste",
  prix:222,
  lieu:"rabat",
  contrat:"CDI",
  dernier_delai:"10-05-2023",
  date_de_publication:"01-03-2023",
  extirieurs_visiteurs:false
},{
  id_annonce:"a2",
  id_recruteur:"r3",
  poste:"poste teste",
  description:"description teste",
  prix:12345,
  lieu:"rabat",
  contrat:"CDI",
  dernier_delai:"10-05-2023",
  date_de_publication:"01-03-2023",
  extirieurs_visiteurs:false
},
{
  id_annonce:"a3",
  id_recruteur:"r2",
  poste:"poste teste",
  description:"description teste",
  prix:12345,
  lieu:"rabat",
  contrat:"CDI",
  dernier_delai:"10-05-2023",
  date_de_publication:"01-03-2023",
  extirieurs_visiteurs:false
},{
  id_annonce:"a4",
  id_recruteur:"r3",
  poste:"poste teste",
  description:"description teste",
  prix:12345,
  lieu:"rabat",
  contrat:"CDI",
  dernier_delai:"10-05-2023",
  date_de_publication:"01-03-2023",
  extirieurs_visiteurs:false
},{
  id_annonce:"a5",
  id_recruteur:"r1",
  poste:"poste teste",
  description:"description teste",
  prix:12345,
  lieu:"rabat",
  contrat:"CDI",
  dernier_delai:"10-05-2023",
  date_de_publication:"01-03-2023",
  extirieurs_visiteurs:false
},{
  id_annonce:"a6",
  id_recruteur:"r3",
  poste:"poste teste",
  description:"description teste",
  prix:12345,
  lieu:"rabat",
  contrat:"CDI",
  dernier_delai:"10-05-2023",
  date_de_publication:"01-03-2023",
  extirieurs_visiteurs:false
},{
  id_annonce:"a7",
  id_recruteur:"r2",
  poste:"poste teste",
  description:"description teste",
  prix:12345,
  lieu:"rabat",
  contrat:"CDI",
  dernier_delai:"10-05-2023",
  date_de_publication:"01-03-2023",
  extirieurs_visiteurs:false
}];
const admin=[
  {id_admin:"a1",
  adresse:"rabat ",
  nom:"ahmad",
  prenom:"ayoub",
  email:"a1@email.com",
  tel:"052334",
  mot_de_passe:"123"
},
{id_admin:"a2",
adresse:"rabat ",
nom:"ahmad",
prenom:"ayoub",
email:"a2@email.com",
tel:"052334",
mot_de_passe:"123"
},
{id_admin:"a3",
adresse:"rabat ",
nom:"ahmad",
prenom:"ayoub",
email:"a3@email.com",
tel:"052334",
mot_de_passe:"123"
}
];
const x=[];
async function ff()
{
  await fetch('https://api-careercruiser.onrender.com/admin')
        .then(response => response.json())
        .then(data => x.push(data));
}
ff();
const Jobs = (state={annonce,admin,x},action)=>{
switch(action.type)
{
  case ALLA:
    return state;
  case ID_A:
    return state;
  case ALL_UTI:
    return state.admin;
  case ALL_ADM:
    return state.admin;
  default :return state;
}
}
export default Jobs;