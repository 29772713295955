import React from 'react';


function Tr_recruteur(props)
{
    function update_recruteur()
    {
        window.location.href=`Update_recruteur/${props.id_}`;
    }
    function sup_annonce()
    {
        fetch(`https://api-careercruiser.onrender.com/recruteur/${props.id_}`, { method: 'DELETE' })
        .then(() => this.setState({ status: 'Delete successful' }));
        window.location.href="Liste_des_recruteurs";
    }
    var auto="";
    if(props.autorisation===true)
    {
        auto="autorisée";
    }else{
        auto="bloquée";
    }

    return(
    <tr>
        <td>{props.nom}</td>
        <td>{props.prenom}</td>
        <td>{props.tel}</td>
        <td>{props.email}</td>
        <td>{props.nom_entreprise}</td>
        <td>{props.domain}</td>
        <td>{auto}</td>
        <th><button onClick={update_recruteur}>modifier</button><button onClick={sup_annonce}>supprimer</button></th>
    </tr>
        );
    
}
export default Tr_recruteur;