import React from 'react';
import '../component/s_nav.css';
import { Link } from 'react-router-dom';
function S_nav ()
{
  function déconnecter()
  {
    //localStorage.removeItem("con");
    localStorage.clear();
  }
    return(
          
          <nav className="s_navbar">
           <ul className="s_nav-links">
           <li className='con_email'>{JSON.parse(localStorage.getItem("con")).email}</li>
           <li><Link to="/Liste_des_annonces">listes des annonces</Link></li>
           <li><Link to="/Ajouter_une_annonce">ajouter une annonce</Link></li>
           <li><Link to="/Liste_des_admin">liste des admin</Link></li>
             <li><Link to="/inscription">ajouter un admine</Link></li>
             <li><Link to="/Liste_des_candidats">liste des candidats</Link></li>
             <li><Link to="/Liste_des_recruteurs">liste des recruteurs</Link></li>
             <li><Link to="/Mes_annances_">mes annonces</Link></li>
             <li className="decon_email"><a href="/" onClick={déconnecter}>déconnecter</a></li>
           </ul>
          </nav>
        );
    
}
export default S_nav;
