import React,{useEffect,useState} from 'react';
import './administration.css';
import S_nav from './s_nav';
import { useSelector,useDispatch } from "react-redux";
import { ALLA } from '../redux/actions/action';
import Tr_annonce from './tr_annonce';

function Liste_des_annonces ()
{    
    var ob;
  const [annonce__,setAnnonce__]=useState([{id_admin:"",poste:"",description:"",email:"",tel:"",ville:"",prix:"",date_de_publication:"",contrat:"",dernier_delai:""}]);
      async function afficher()
  {
    await fetch('https://api-careercruiser.onrender.com/annonce')
    .then(response => response.json())
    .then(data => ob = data);
    if(annonce__==undefined||annonce__[0].poste=="")
    {
        setAnnonce__(ob);  
    }
    
  }
      useEffect(()=>{
      afficher();
      console.log(annonce__);
      },[annonce__])
    return(<>
    <div class="parent_">
        <div class="div1_"><S_nav/> </div>
        <div class="div2_"> 
<div class="table-wrapper">
    <table class="fl-table">
        <thead>
            <tr>
                <th>poste</th>
                <th>date de publication</th>
                <th>dernier delai</th>
                <th></th>
            </tr>
        </thead>
            {annonce__.map((emploi)=>(<Tr_annonce id_annonce={emploi._id}  poste={emploi.poste} date_de_publication={emploi.date_de_publication} dernier_delai={emploi.dernier_delai}/>))}
            
    </table>
</div>
        </div>
        
    </div>
    </>
        );
    
}
export default Liste_des_annonces;