import React from 'react';
import './annonce.css';

function Annonce (props)
{
    function selection_annonce()
    {
        window.location.href=`infoannonce/${props.id_a}`;
    }
    
        return(
    <div className="job" style={{backgroundImage: `url(${props.Background})`}} >
        <p><span className="label">ID :</span> {props.id_a}</p><br/>
      <h2 className="job-title">{props.poste}</h2>
      <div className="job-details" >
        <p><span className="label">Contract Type:</span> {props.contrat}</p>
        <p><span className="label">salary:</span> {props.prix} DH</p>
        <p><span className="label">Place:</span> {props.lieu}</p>
      </div>
      <button className='_button' onClick={selection_annonce}><span><strong>afficher les détail</strong></span><i></i></button>
    </div>
    );
    
}
export default Annonce;