import React,{ useRef,useState,useEffect } from 'react';
import S_nav2 from './s_nav2';
import './administration.css';

function Info_recruteur()
{   
  const [recruteur,setRecruteur]=useState({nom:"",prenom:"",adresse:"",email:"",tel:"",mot_de_passe:"",domain:"",description:"",nom_entreprise:""});
  const _nom =useRef();
  const _prenom=useRef();
  const _adresse=useRef();
  const _email=useRef();
  const _mot_de_passe=useRef();
  const _mot_de_passe_=useRef();
  const _tel=useRef();
  const _nom_entreprise=useRef();
  const _domaine=useRef();
  const _description=useRef();
  let vst=JSON.parse(localStorage.getItem("con"));
  var ob;
  
  async function afficher()
{
  await fetch(`https://api-careercruiser.onrender.com/recruteur/${vst.ID_ADM}`)
.then(response => response.json())
.then(data => ob = data);
if(recruteur==undefined||recruteur.nom=="")
{
    setRecruteur(ob);  
}
}

  useEffect(()=>{
  afficher();
  },[recruteur])

  /************************************************ */
  async function update_recruteur()
    {
      var noveau_recruteur_info={};
      
      if(_nom.current.value!=="")
      {
        const nom=_nom.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{nom}};
        console.log(noveau_recruteur_info);
      }
      if(_prenom.current.value!=="")
      {
        const prenom=_prenom.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{prenom}};
        console.log(noveau_recruteur_info);
      }
      if(_tel.current.value!=="")
      {
        const tel=_tel.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{tel}};
        console.log(noveau_recruteur_info);
      }
      if(_adresse.current.value!=="")
      {
        const adresse=_adresse.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{adresse}};
        console.log(noveau_recruteur_info);
      }
      if(_email.current.value!=="")
      {
        const email=_email.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{email}};
        console.log(noveau_recruteur_info);
      }
      if(_nom_entreprise.current.value!=="")
      {
        const nom_entreprise=_nom_entreprise.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{nom_entreprise}};
        console.log(noveau_recruteur_info);
      }
      if(_domaine.current.value!=="")
      {
        const domaine=_domaine.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{domaine}};
        console.log(noveau_recruteur_info);
      }
      if(_description.current.value!=="")
      {
        const description=_description.current.value;
        noveau_recruteur_info={...noveau_recruteur_info,...{description}};
        console.log(noveau_recruteur_info);
      }
      if(_mot_de_passe.current.value!=="")
      {
        if(_mot_de_passe.current.value===_mot_de_passe_.current.value)
        {
          const mot_de_passe=_mot_de_passe.current.value;
          noveau_recruteur_info={...noveau_recruteur_info,...{mot_de_passe}};
          console.log(noveau_recruteur_info);
        }else{
          alert("Vérifiez le mot de passe");
        }
      }
     
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(noveau_recruteur_info)
         };
      const response = await fetch(`https://api-careercruiser.onrender.com/recruteur/${vst.ID_ADM}`, requestOptions);
      const data = await response.json();
    }
    return(<>
    <div class="parent_">
    <div class="div1_">
      <S_nav2/>
    </div>
        <div class="div2_">
        <div class="signup upd">
      <div>
        <h1>Information personnel</h1>
        <span className='rdyo'>
        nom
          <input type="text" ref={_nom}  placeholder={recruteur.nom} required=""/>
        </span>
        <span className='rdyo'>
        prenom
          <input type="text" ref={_prenom}  placeholder={recruteur.prenom} required=""/>
        </span>
        <span className='rdyo'>
        adresse
          <input type="text" ref={_adresse}  placeholder={recruteur.adresse} required=""/>
        </span>
        <span className='rdyo'>
        email
         <input type="email" ref={_email}  placeholder={recruteur.email} required=""/>
        </span>
        <span className='rdyo'>
        mot_de_passe
          <input type="password" ref={_mot_de_passe}  placeholder={recruteur.mot_de_passe} required=""/>
        </span>
        <span className='rdyo'>
        mot_de_passe
        <input type="password" ref={_mot_de_passe_}  placeholder={recruteur.mot_de_passe} required=""/>
        </span>
        <span className='rdyo'>
        tel
         <input type="text" ref={_tel}  placeholder={recruteur.tel} required=""/>
        </span>
          <span className='rdyo' id='recruteur'>
            nom_entreprise
          <input type="text" ref={_nom_entreprise}  placeholder={recruteur.nom_entreprise} required=""/>
        </span>
        <span className='rdyo' id='recruteur'>
        domain
          <input type="text" ref={_domaine}  placeholder={recruteur.domain} required=""/>
        </span>
        <span className='rdyo' id='recruteur'>
        description
          <input type="text" ref={_description}  placeholder={recruteur.description} required=""/>
        </span>
        <button onClick={update_recruteur} >enregistrer la modification</button>
      </div>
    </div>
        </div>
    </div>
    </>
        );
    
}
export default Info_recruteur;