//Profil_condidat_
import React, { useState } from 'react';
import { useRef,useEffect } from 'react';
import S_nav from './s_nav';
import { useParams } from 'react-router-dom';
function Profil_condidat_()
{
    let { id } = useParams();
    var ob,ob2,ob3;
    const [candidat,setCandidat]=useState({nom:"",prenom:"",adresse:"",email:"",tel:"",mot_de_passe:"",genre:"",date_de_naissance:"",niveau_etude:"",photo_url:""});
    const [dip,setDip]=useState([{id_candidat:"",annee_obtenire:"",filiere:"",note:"",domaine:""}]);
    const [exp,setExp]=useState([{ id_candidat:"",domaine:"",annee_experience:"",poste:""}])
  //-------------------------------------------------------------
  async function afficher()
  {
        await fetch(`https://api-careercruiser.onrender.com/candidat/${id}`)
        .then(response => response.json())
        .then(data => ob = data);
        if(candidat==undefined||candidat.nom=="")
        {
            setCandidat(ob);  
        }
  }
  useEffect(()=>{
      afficher();
      },[candidat])

      //---------------------------------------------------

      async function afficher2()
      {
            await fetch(`https://api-careercruiser.onrender.com/diplome/${id}`)
            .then(response => response.json())
            .then(data => ob2 = data);
            if(dip==undefined||dip[0].id_candidat=="")
            {
                setDip(ob2);  
            }
      }
      useEffect(()=>{
          afficher2();
          },[dip])

    //---------------------------------------------------

      async function afficher3()
      {
            await fetch(`https://api-careercruiser.onrender.com/experience/${id}`)
            .then(response => response.json())
            .then(data => ob3 = data);
            if(exp==undefined||exp[0].id_candidat=="")
            {
                setExp(ob3);  
            }
      }
      useEffect(()=>{
          afficher3();
          },[exp])

        let Background="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiqVUqd-oUy6wcVEGQSTr9THVNdeFqNMhS-Q&usqp=CAU";
    return(<>
    <div class="parent_">
    <div class="div1_"><S_nav/> </div>
    <div class="div2_">
        <div class="div2_Profil_condidat_">
            <div class="div1_pc">
                <h1>diplome</h1>
                {dip.map((dip)=>(<> <hr style={{width:'50%',textAlign:"left",marginLeft:0}}/> 
                    domaine : {dip.domaine}<br/>
                    filiere : {dip.filiere}<br/>
                    note : {dip.note}<br/>
                    annee obtenire: {dip.annee_obtenire}<br/>
                </>
                ))}
            </div>
            <div class="div2_pc">
              <h1>expérience</h1>
                {exp.map((exp)=>(<> <hr style={{width:'50%',textAlign:"left",marginLeft:0}}/> 
                    domaine : {exp.domaine}<br/>
                    poste : {exp.poste}<br/>
                    annee experience : {exp.annee_experience}<br/>
                </>
                ))}
            </div>
            <div class="div3_pc">
                nom : {candidat.nom}<br/> 
                prenom : {candidat.prenom}<br/>
                date_de_naissance : {candidat.date_de_naissance}<br/>
                adresse : {candidat.adresse}<br/>
                email : {candidat.email}<br/>
                tel : {candidat.tel}<br/>
                niveau_etude : {candidat.niveau_etude}<br/>
            </div>
            <div class="div4_pc" style={{backgroundImage: `url(${candidat.photo_url})`,backgroundRepeat:"no-repeat"}} ></div>
        </div>
    </div>
    </div>
    </>
        );
}
export default Profil_condidat_;