//Ajouter_une_annonce2
import React, { useRef } from 'react';
import S_nav2 from './s_nav2';
function Ajouter_une_annonce2()
{
    const post_=useRef();
  const description=useRef();
  const email =useRef();
  const tel =useRef();
  const ville =useRef();
  const prix=useRef();
  const contrat=useRef();
  const dernier_delai=useRef();
  const _photo_url=useRef();
  const id_admin =JSON.parse(localStorage.getItem('con')).ID_ADM;
   //const {id_admin,poste,description,email,tel,ville,prix,contrat,dernier_delai}=req.body;
 function Ajouter_annonce()
  {
    if((id_admin!==null)&&(post_.current.value!==""))
    {
      const post={
        id_admin:id_admin,
        poste:post_.current.value,
        description:description.current.value,
        email:email.current.value,
        tel:tel.current.value,
        ville:ville.current.value,
        prix:prix.current.value,
        contrat:contrat.current.value,
        photo_url:_photo_url.current.value,
        dernier_delai:dernier_delai.current.value};
        console.log(post)
        fetch("https://api-careercruiser.onrender.com/annonce",{
              method:"POST",
              headers:{"content-type":"application/json"},
             body:JSON.stringify(post)
            }).then(()=>{
              alert("l'annonce ajouté avec succès");
            })
    }else
    {
      alert("champ poste et obligatoire");
    }
  }
    return(<>
    <div class="parent_">
    <div class="div1_"><S_nav2/> </div>
        <div class="div2_">
        <div className="card">
  <h2>création de nouveau annonce</h2>
  <div className="row">
  <div className="form-group">
        <label>photo url</label>
        <input ref={_photo_url} required type="_photo_url" placeholder=''/>
      </div>
      <div className="form-group">
        <label>poste</label>
        <input ref={post_} required type="text" placeholder='developer full stack , commercial , chef projet ,...'/>
      </div>
      <div className="form-group">
        <label>contrat</label>
        <input ref={contrat} type="text" placeholder='CDI,CDD,...'/>
      </div>
      <div className="form-group">
        <label>salaire</label>
        <input ref={prix} type="number" min={0} aria-valuenow={0} placeholder='5000'/>
      </div>
      <div className="form-group">
        <label>lieu</label>
        <input ref={ville} type="text" placeholder='rabat,casablanca,marrakech,...'/>
      </div>
      <div className="form-group">
        <label>Email</label>
        <input ref={email} type="email" pattern=".+@globex\.com" required placeholder='exemple@email.com'/>
      </div>
      <div className="form-group">
        <label>tel</label>
        <input ref={tel} type="text" placeholder='06.10.11.12.13'/>
      </div>
      <div className="form-group">
        <label>dernier delai</label>
        <input ref={dernier_delai} type="text" placeholder='jj-mm-aaaa'/>
      </div>
      <div className="form-group">
        <label>description</label>
        <textarea ref={description} placeholder="Description de l'emploi..."></textarea>
      </div>
    <div className="form-group_f">
      {/* <input type="button" value="publier"/> */}
      <button onClick={Ajouter_annonce}>publier</button>  
    </div>
    
  </div>
</div>
        </div>
    </div>
    </>
        );
}
export default Ajouter_une_annonce2;