import React from 'react';
import {  useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import './annonce.css';
function Info_annonce ()
{
  let { id_a } = useParams();
  var ob;
  const [annonce__,setAnnonce__]=useState({id_admin:"",poste:"",description:"",email:"",tel:"",ville:"",prix:"",date_de_publication:"",contrat:"",dernier_delai:"",photo_url:""});
  async function afficher()
  {
    await fetch(`https://api-careercruiser.onrender.com/annonce/${id_a}`)
    .then(response => response.json())
    .then(data => ob = data);
    if(annonce__==undefined||annonce__.poste=="")
    {
        setAnnonce__(ob);  
    }
    
  }
  afficher();
useEffect(()=>{
  afficher();
  checked_postulation();
},[annonce__]
);
var ob;
async function checked_postulation ()
{
  await fetch('https://api-careercruiser.onrender.com/postuler')
  .then(response => response.json())
  .then(data => ob = data);

  let vst=JSON.parse(localStorage.getItem("con"));
  for(const user of ob)
  {
    if((vst.ID_ADM===user.id_candidat)&&(id_a===user.id_annonce))
    {
      document.getElementById("psl").disabled = true;
      document.getElementById("psl").textContent="vous déja postuler";
    }
  }
}
  function postuler()
  {
    if(JSON.parse(localStorage.getItem("con"))==null)
    {
      window.location.href="/Connect_candidat";
    }else{
      let vst=JSON.parse(localStorage.getItem("con"))
      const postulation={
        id_candidat:vst.ID_ADM,
        id_annonce:id_a,
        poste_postuler:annonce__.poste
      }
      fetch("https://api-careercruiser.onrender.com/postuler",{
            method:"POST",
            headers:{"content-type":"application/json"},
            body:JSON.stringify(postulation)
          }).then(()=>{
            alert("postuler avec succès");
          })
          window.location.reload();
    }
  }
    return(<>
    <div style={{height:"100vh",padding:"10vh 4%"}}>
      <img src={annonce__.photo_url} alt='img' style={{width:"50%",height:"30vh",marginLeft:"25%",borderRadius:"10px"}}></img>
      <h2>poste vacant        : {annonce__.poste}</h2>
      <h2>salaire                : {annonce__.prix}</h2>
      <h2>contrat             : {annonce__.contrat}</h2>
      <h2>ville               : {annonce__.ville}</h2>
      <h2>date de publication : {annonce__.date_de_publication}</h2>
      <h2>dernier delai       : {annonce__.dernier_delai}</h2>
      <h2>description         :</h2>
      <p>{annonce__.description}</p>
      <button id='psl' className='_button' onClick={postuler}>postuler</button>
    </div>
    </>
        );
    
}
export default Info_annonce;